import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, FactoryProvider, Provider } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
	ConnectEnvironment,
	environmentToken,
} from '@environments/environment';
import { LOCATION } from '@ng-web-apis/common';

function initializeSplashScreenConfigFactory(
	environment: ConnectEnvironment,
	location: Location,
	document: Document
): () => void {
	return () => {
		let usedSplashScreen: 'DEFAULT' | 'NOVO' = 'DEFAULT';
		if (Capacitor.isNativePlatform()) {
			const webUrl = environment.wrappedApp.webUrl;
			if (webUrl?.includes('novo')) {
				// TODO: Introduce a proper environment setting for this directly [MAB]
				// Or better insert it at build time
				usedSplashScreen = 'NOVO';
			}
		} else {
			const isNovoUrl =
				location.hostname.match(/^(?:.+\.)?novo-connect\.com$/) ||
				location.hostname.match(/^(?:.+\.)?novo-connect\.eu$/) ||
				location.hostname.match(/^(?:.+\.)?novo-connect\.dk$/);
			if (isNovoUrl) {
				usedSplashScreen = 'NOVO';
			}
		}
		localStorage.setItem('USED_SPLASH_SCREEN', usedSplashScreen);
		document.dispatchEvent(new Event('SPLASH_SCREEN_SET'));
	};
}

const splashScreenConfigInitializer: FactoryProvider = {
	provide: APP_INITIALIZER,
	multi: true,
	deps: [environmentToken, LOCATION, DOCUMENT],
	useFactory: initializeSplashScreenConfigFactory,
};

export function provideSplashscreenConfig(): Provider[] {
	return [splashScreenConfigInitializer];
}
