<ng-container *ngIf="!preview || !canPreview">
	<i [class]="icon"></i>
	<p class="name">{{ fileData?.fileName || "--" }}</p>
	<button
		*ngIf="allowDownload"
		mat-button
		color="primary"
		(click)="downloadFile()"
		[disabled]="!fileData || isDownloading"
	>
		<div class="tw-flex tw-flex-row">
			<co-spinner layout="inline" class="tw-mr-2" *ngIf="isDownloading" />
			{{ isDownloading ? "Downloading..." : "Download File" }}
		</div>
	</button>
	<button
		mat-button
		color="accent"
		(click)="preview = true"
		*ngIf="canPreview"
	>
		Preview File
	</button>
</ng-container>

<iframe
	*ngIf="preview && canPreview"
	[src]="
		'https://view.officeapps.live.com/op/embed.aspx?src=' + encodedUrl
			| coBypassSecurityTrustResourceUrl
	"
></iframe>
