import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	addSidepanelItem,
	hideSidepanelItem,
	hideSidepanelSection,
	loadSidepanel,
	moveSidepanelItem,
	moveSidepanelItemToSection,
	updateSidepanelItem,
} from '@consensus/connect/ufa/sidepanel/data-access';
import { TokenService } from '@consensus/shared/shared/iam/data-access';
import { takeUntil } from 'rxjs';
import { refreshEvent } from '@store/scope';
import { ColearnSettings, MoveModel, SocketDeleteModel } from '@shared/models';
import {
	addSessionItem,
	loadSessionItems,
	removeSessionItem,
} from '@consensus/connect/ufa/agenda/data-access';
import { EventSessionItem } from '@consensus/connect/ufa/agenda/domain';
import { AuthedUser, SimpleGroup } from '@shared/models';
import { loadDashboards } from '@consensus/connect/ufa/dashboard/data-access';

import { sessionItemActiveChanged } from '@consensus/connect/cms/agenda/data-access';
import {
	addEliminationGame,
	advanceEliminationGame,
	loadEliminationGames,
	removeEliminationGame,
	setEliminationGameTeam,
	updateEliminationGame,
} from '@consensus/connect/ufa/elimination-game/data-access';
import {
	addSignature,
	loadSignatures,
	removeSignature,
	removeUserSignature,
	Signature,
	updateSignature,
} from '@consensus/connect/ufa/signature/data-access';
import { parseObject } from '@lib/helpers';
import { parseDate } from '@lib/helpers';
import { loadLivestreams } from '@consensus/connect/ufa/livestream/data-access';
import {
	addUserQaQuestion,
	QaQuestion,
	QaTopic,
	removeQaQuestion,
	removeQaSession,
	removeQaSessions,
	removeQaTopic,
	setModQaQuestion,
	setQaSession,
	setQaSessions,
	setQaSettings,
	setQaTopic,
} from '@consensus/connect/ufa/qa/data-access';
import { dispatch, redispatchAsync } from '@lib/redux';
import { SocketActionConfig } from '@lib/redux';
import { AuthService } from '@consensus/shared/shared/iam/data-access';
import { socketConfigs, socketConfigs$ } from '@lib/redux';
import {
	ColearnBadge,
	ColearnCompetition,
	ColearnProgressSection,
	EngagementAggregate,
	EngagementTotalUpdate,
} from '@consensus/connect/ufa/colearn/domain';
import {
	colearnCompetitionRemoved,
	colearnCompetitionUpdated,
	colearnProgressSectionsUpdated,
	colearnProgressSettingsUpdated,
	colearnSettingsUpdated,
} from '@consensus/connect/ufa/colearn/data-access-state';
import { loadModularDashboards } from '@consensus/connect/ufa/modular-dashboard/data-access';
import {
	addUserGroup,
	selectUserGroups,
	selectUserId,
	removeUserGroup,
	setAuthUser,
} from '@store/user';
import { logOut } from '@store/global';
import { CoSnackService } from '@consensus/co/ui-snackbars';
import { SelfieService } from '@consensus/connect/shared/selfie/data-access';
import {
	addPushoutResults,
	loadPushoutResults,
	removePushoutResults,
} from '@consensus/connect/ufa/pushout-results/data-access';
import { LivestreamService } from '@consensus/connect/shared/livestream/data-access';
import { ColearnService } from '@consensus/connect/shared/colearn/data-access';
import { loadOnlineUsers } from '@consensus/shared/cms/users/data-access';
import { LearningPackageService } from '@consensus/shared/shared/learning-package/data-access';
import { ConnectSharedDataAccessWebsocketService } from '@consensus/connect/shared/data-access-websocket';
import {
	CmsEliminationGameService,
	CmsEliminationGameResultState,
} from '@consensus/connect/cms/elimination-game/data-access';
import { CmsEliminationGameResult } from '@consensus/connect/cms/elimination-game/domain';
import { SidepanelItem } from '@consensus/connect/shared/sidepanel/domain';
import { UfaPushoutService } from '@consensus/connect/ufa/pushout/data-access';
import { SessionItemActiveChange } from '@consensus/connect/shared/agenda/domain';
import {
	UfaEliminationGame,
	UfaEliminationGameItem,
	UfaEliminationGameTeam,
} from '@consensus/connect/ufa/elimination-game/domain';
import { EliminationGameState } from '@consensus/connect/shared/elimination-game/domain';
import { QaSettings } from '@consensus/connect/shared/qa/domain';
import { loadPageTheming } from '@store/theming';

@Injectable({ providedIn: 'root' })
export class WebsocketService {
	readonly #learningPackageService = inject(LearningPackageService);
	readonly #colearnService = inject(ColearnService);
	readonly #snackService = inject(CoSnackService);
	readonly #authService = inject(AuthService);
	readonly #livestreamService = inject(LivestreamService);
	readonly #selfieService = inject(SelfieService);
	readonly #ufaPushoutService = inject(UfaPushoutService);
	readonly #cmsEliminationGameService = inject(CmsEliminationGameService);
	readonly #tokenService = inject(TokenService);
	readonly #store = inject(Store);
	readonly #wsService = inject(ConnectSharedDataAccessWebsocketService);

	#userId: string;
	#userGroups: string[];

	constructor() {
		// eslint-disable-next-line @ngrx/no-store-subscription
		this.#store.select(selectUserGroups).subscribe(g => (this.#userGroups = g));
		// eslint-disable-next-line @ngrx/no-store-subscription
		this.#store.select(selectUserId).subscribe(id => (this.#userId = id));
	}

	#afterConnect() {
		redispatchAsync(loadDashboards);
		redispatchAsync(loadModularDashboards);
		redispatchAsync(loadPageTheming);
		redispatchAsync(loadLivestreams);
		// TODO(@LayZeeDK): Resolve by dispatching actions in separate effects
		/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
		this.#store.dispatch(loadSessionItems.start());
		this.#store.dispatch(loadEliminationGames.start());
		this.#store.dispatch(loadPushoutResults.start());
		this.#store.dispatch(loadSidepanel.start());
		this.#store.dispatch(loadSignatures.start());
		this.#store.dispatch(refreshEvent.start());
		/* eslint-enable @ngrx/avoid-dispatching-multiple-actions-sequentially */
	}

	#subscribe() {
		socketConfigs.forEach(config => this.applySocketConfig(config));

		socketConfigs$
			.pipe(takeUntil(this.#wsService.connectionClosed))
			.subscribe(c => {
				this.applySocketConfig(c);
			});

		this.#wsService
			.action<UfaEliminationGame>(
				'[Elimination Games] Elimination Game: Started'
			)
			.subscribe(game => {
				this.#cmsEliminationGameService.eliminationGameStates$.next(game.state);
				this.#store.dispatch(addEliminationGame(game));
			});

		this.#wsService
			.action<string>('[Elimination Games] Elimination Game: Stopped')
			.subscribe(id => {
				this.#cmsEliminationGameService.eliminationGameStates$.next({
					eliminationGameId: id,
					running: false,
				});
				this.#store.dispatch(removeEliminationGame(id));
			});

		this.#wsService
			.action<UfaEliminationGameItem>(
				'[Elimination Games] Elimination Game: Next Item'
			)
			.subscribe(s => {
				this.#cmsEliminationGameService.eliminationGameStates$.next({
					eliminationGameId: s.eliminationGameId,
					activeItemId: s.id,
					showOptions: false,
					showResults: false,
				});
				this.#store.dispatch(advanceEliminationGame(s));
			});

		this.#wsService
			.action<EliminationGameState>(
				'[Elimination Games] Elimination Game: State Update'
			)
			.subscribe(s => {
				this.#cmsEliminationGameService.eliminationGameStates$.next(s);
				this.#store.dispatch(updateEliminationGame(s));
			});

		this.#wsService
			.action<UfaEliminationGameTeam>(
				'[Elimination Games] Elimination Game: Set Team'
			)
			.subscribe(data => this.#store.dispatch(setEliminationGameTeam(data)));

		this.#wsService
			.action<CmsEliminationGameResultState>(
				'[Elimination Games] Elimination Game: Result State Update'
			)
			.subscribe(s => this.#cmsEliminationGameService.resultStates$.next(s));

		this.#wsService
			.action<string>('[Pushouts] Pushout answers: Deleted')
			.subscribe(s => this.#ufaPushoutService.deleteAnswers(s));

		this.#wsService
			.action<{ selfieId: string; show: boolean }>('[Selfie] Overlay: Toggle')
			.subscribe(x => this.#selfieService.overlayUpdates$.next(x));

		this.#wsService
			.action<string>('[Livestreams] Livestream: Sync')
			.subscribe(id => this.#livestreamService.sync(id));

		this.#wsService
			.action<Signature>('[Signatures] Signature: Start')
			.subscribe(data => this.#store.dispatch(addSignature(data)));

		this.#wsService
			.action<string>('[Signatures] Signature: End')
			.subscribe(data => this.#store.dispatch(removeSignature(data)));

		this.#wsService
			.action<Signature>('[Signatures] Signature: Update')
			.subscribe(data => this.#store.dispatch(updateSignature(data)));

		this.#wsService
			.action<string>('[Signatures] UserSignature: Remove')
			.subscribe(data => this.#store.dispatch(removeUserSignature(data)));

		this.#wsService
			.action<QaSettings>('[QA] Settings: Set')
			.subscribe(data => this.#store.dispatch(setQaSettings(data)));

		this.#wsService
			.action<string>('[QA] Session: Set')
			.subscribe(data => this.#store.dispatch(setQaSession(data)));

		this.#wsService
			.action<string>('[QA] Session: Removed')
			.subscribe(data => this.#store.dispatch(removeQaSession(data)));

		this.#wsService
			.action<string[]>('[QA] Sessions: Set')
			.subscribe(data => this.#store.dispatch(setQaSessions(data)));

		this.#wsService
			.action<string[]>('[QA] Sessions: Removed')
			.subscribe(data => this.#store.dispatch(removeQaSessions(data)));

		this.#wsService
			.action<QaTopic>('[QA] Topic: Set')
			.subscribe(data => this.#store.dispatch(setQaTopic(data)));

		this.#wsService
			.action<string>('[QA] Topic: Removed')
			.subscribe(data => this.#store.dispatch(removeQaTopic(data)));

		this.#wsService.action<QaQuestion>('[QA] Question: Added').subscribe(x => {
			this.#store.dispatch(addUserQaQuestion(parseObject(x, parseDate)));
		});

		this.#wsService.action<QaQuestion>('[QA] Question: Set').subscribe(x => {
			this.#store.dispatch(setModQaQuestion(parseObject(x, parseDate)));
		});

		this.#wsService
			.action<string>('[QA] Question: Removed')
			.subscribe(data => this.#store.dispatch(removeQaQuestion(data)));

		this.#wsService.action('[Colearn] Engagement: Updated').subscribe(() => {
			this.#colearnService.engagementSocketUpdate(this.#userId);
		});

		this.#wsService
			.action<EngagementTotalUpdate[]>('[Colearn] Engagement Totals: Updated')
			.subscribe(engagements => {
				this.#colearnService.engagementTotalsSocketUpdate(
					this.#userId,
					engagements
				);
			});

		this.#wsService
			.action<EngagementAggregate>('[Colearn] User Engagement: Updated')
			.subscribe(engagement => {
				this.#colearnService.userEngagementSocketUpdate(
					this.#userId,
					engagement
				);
			});

		this.#wsService.action('[Colearn] Badge: Updated').subscribe(() => {
			this.#colearnService.badgeSocketUpdate(this.#userId);
		});

		this.#wsService
			.action<ColearnBadge[]>('[Colearn] User Badge: Updated')
			.subscribe(badges => {
				this.#colearnService.userBadgeSocketUpdate(this.#userId, badges);
			});

		this.#wsService
			.action<number>('[Colearn] User Score: Updated')
			.subscribe(score => {
				this.#colearnService.userScoreSocketUpdate(this.#userId, score);
			});

		this.#wsService
			.action<ColearnSettings>('[Colearn] Settings: Updated')
			.subscribe(data => this.#store.dispatch(colearnSettingsUpdated(data)));

		this.#wsService
			.action<ColearnCompetition>('[Colearn] Competition: Updated')
			.subscribe(data => this.#store.dispatch(colearnCompetitionUpdated(data)));

		this.#wsService
			.action<string>('[Colearn] Competition: Removed')
			.subscribe(data => this.#store.dispatch(colearnCompetitionRemoved(data)));

		this.#wsService
			.action<ColearnProgressSection[]>('[Colearn] Progress Sections: Updated')
			.subscribe(data =>
				this.#store.dispatch(colearnProgressSectionsUpdated(data))
			);

		this.#wsService
			.action<any>('[Colearn] Progress Settings: Updated')
			.subscribe(data =>
				this.#store.dispatch(colearnProgressSettingsUpdated(data))
			);

		this.#wsService
			.action<string>('[EventFileImporter] Import: Succeeded')
			.subscribe(msg =>
				this.#snackService.success(msg, 'Event File Import Success')
			);

		this.#wsService
			.action<string>('[EventFileImporter] Import: Failed')
			.subscribe(msg =>
				this.#snackService.error(msg, 'Event File Import Failure')
			);

		this.#wsService
			.action<string>('[Academy] LearningPackage: Error')
			.subscribe(msg => this.#snackService.error(msg, 'SCORM module error'));

		this.#wsService
			.action<string>('[Academy] LearningPackage: Exit')
			.subscribe(() => {
				this.#learningPackageService.exitLearningPackage = true;
			});

		this.#wsService
			.action<SidepanelItem>('ShowSidepanelItem')
			.subscribe(data => {
				this.#store.dispatch(addSidepanelItem(data));
			});

		this.#wsService
			.action<SocketDeleteModel>('HideSidepanelItem', { raw: true })
			.subscribe(data => {
				if (!data.groups.some(x => this.#userGroups.includes(x))) {
					this.#store.dispatch(hideSidepanelItem(data.id));
				}
			});

		this.#wsService.action<string>('HideSidepanelSection').subscribe(data => {
			this.#store.dispatch(hideSidepanelSection(data));
		});

		this.#wsService
			.action<SidepanelItem>('UpdateSidepanelItem')
			.subscribe(data => {
				this.#store.dispatch(updateSidepanelItem(data));
			});

		this.#wsService
			.action<MoveModel>('UpdateSidepanelItemIndex')
			.subscribe(data => {
				this.#store.dispatch(moveSidepanelItem(data));
			});

		this.#wsService
			.action<MoveModel & { section: string }>('MoveSidepanelItemToSection')
			.subscribe(data => {
				this.#store.dispatch(moveSidepanelItemToSection(data));
			});

		this.#wsService.action<AuthedUser>('UpdateAuthedUser').subscribe(data => {
			this.#store.dispatch(setAuthUser(data));
			this.#authService.verifyToken();
		});

		this.#wsService.action('ForceUserLogout').subscribe(() => {
			this.#store.dispatch(logOut({ customRoute: null }));
		});

		this.#wsService
			.action<EventSessionItem>('AddPushout')
			.subscribe(pushout => {
				this.#store.dispatch(addSessionItem(pushout));
			});

		this.#wsService.action<string>('RemovePushout').subscribe(id => {
			this.#store.dispatch(removeSessionItem(id));
			this.#ufaPushoutService.removeClosedPushout(id);
		});

		this.#wsService
			.action<CmsEliminationGameResult>('AddPushoutResults')
			.subscribe(pushout => {
				this.#store.dispatch(addPushoutResults(pushout));
			});

		this.#wsService.action<string>('RemovePushoutResults').subscribe(id => {
			this.#store.dispatch(removePushoutResults(id));
		});

		this.#wsService
			.action<SessionItemActiveChange>('AdminSessionItemsActiveChanged')
			.subscribe(sessionItem => {
				this.#store.dispatch(sessionItemActiveChanged(sessionItem));
			});

		this.#wsService
			.action<SimpleGroup>('UserAddedToGroup')
			.subscribe(simpleGroup => {
				// Add group from user store:
				dispatch(addUserGroup, simpleGroup.id);
				this.#afterConnect();
			});

		this.#wsService
			.action<string>('UserRemovedFromGroup')
			.subscribe(groupId => {
				// Remove group from user store:
				dispatch(removeUserGroup, groupId);
				this.#afterConnect();
			});

		this.#wsService
			.action(`[Users] Connections: Refresh`)
			.subscribe(() => redispatchAsync(loadOnlineUsers));
	}

	async startConnection({
		eventId,
		isCms = false,
	}: {
		readonly eventId?: string;
		readonly isCms?: boolean;
	}) {
		const starting = this.#wsService.startConnection({
			eventId,
			isCms,
			accessTokenFactory: () => this.#tokenService.getLoginToken(),
		});

		this.#wsService.connectionState$
			.pipe(takeUntil(this.#wsService.connectionClosed))
			.subscribe(x => {
				if (x === 'Connected') {
					if (!isCms) {
						this.#afterConnect();
					}
				}
			});

		if (!starting) {
			return;
		}
		this.#subscribe();

		await starting;
	}

	applySocketConfig(config: SocketActionConfig<unknown, unknown>) {
		this.#wsService.action(config.socketString).subscribe(data => {
			this.#store.dispatch(config.action(config.mapper(data ?? null)));
		});
	}
}
