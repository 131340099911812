export interface LoginModel {
	username: string;
	password: string;
}

export interface AnonLoginModel {
	eventId: string;
	password: string;
}

export interface AnonUpgradeModel {
	email: string;
	firstName?: string;
	lastName?: string;
	password: string;
}

export interface PinLoginModel {
	eventId?: string;
	clientId?: string;
	lastName: string;
	pin: number;
}

export const LOGIN_REDIRECT_ROUTE_CLAIM_NAME =
	'overrideDefaultLoginRedirectRoute';
