import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LandingPageType, SlugQuery } from '@shared/models';
import { SupportedLocale } from '@consensus/connect/shared/globalization/util';
import {
	EventAdminModel,
	EventCreateModel,
	EventInfoDto,
	EventLoadModel,
	EventUpdateModel,
	UpdateClientOptions,
	AffectedUsersResponse,
} from '@consensus/shared/shared/event/domain';
import {
	Client,
	clientViewModelResponseSchema,
	samlLoginProviderResponseSchema,
} from '@consensus/shared/shared/client/domain';
import { FileTypes } from '@consensus/shared/shared/files/domain';
import { environmentToken } from '@environments/environment';
import { map, Observable } from 'rxjs';
import * as v from 'valibot';

const dateTimeNullableSchema = v.nullable(
	v.union(
		[
			v.pipe(
				v.string(),
				v.isoTimestamp(),
				v.transform(x => new Date(x))
			),
			v.date(),
		],
		'dateTimeNullableSchema'
	)
);

const eventInfoDtoResponseSchema = v.pipe(
	v.object(
		{
			id: v.string(),
			name: v.string(),
			shortName: v.nullable(v.string()),
			clientId: v.string(),
			slug: v.string(),
			timezone: v.string(),
			start: dateTimeNullableSchema,
			end: dateTimeNullableSchema,
			locale: v.nullable(v.enum(SupportedLocale, 'SupportedLocale')),
			hidden: v.boolean(),
			forceLivestreamFullscreen: v.boolean(),
			forceOpenSidepanelWhenLivestreamIsActive: v.boolean(),
			arPopulationJson: v.nullable(v.string()),
			alternativeIdentifiers: v.pipe(
				v.array(v.string(), 'EventInfoDto.alternativeIdentifiers'),
				v.readonly()
			),
			isPortalEvent: v.boolean(),
		},
		'EventInfoDto'
	),
	v.readonly()
);

const simpleEventViewModelResponseSchema = v.pipe(
	v.object(
		{
			id: v.string(),
			alternativeIdentifiers: v.pipe(
				v.array(v.string(), 'SimpleEventViewModel.alternativeIdentifiers'),
				v.readonly()
			),
			name: v.string(),
			shortName: v.nullable(v.string()),
			slug: v.string(),
			timezone: v.string(),
			locale: v.nullable(v.enum(SupportedLocale, 'SupportedLocale')),
			start: dateTimeNullableSchema,
			end: dateTimeNullableSchema,
			hidden: v.boolean(),
			forceLivestreamFullscreen: v.boolean(),
			forceOpenSidepanelWhenLivestreamIsActive: v.boolean(),
			clientId: v.string(),
			isPortalEvent: v.boolean(),
			portalSortingKey: v.pipe(v.number(), v.integer()),
		},
		'SimpleEventViewModel'
	),
	v.readonly()
);
const eventViewModelResponseSchema = v.pipe(
	v.object(
		{
			...simpleEventViewModelResponseSchema.entries,
			oauthSingleSignOnEnabled: v.boolean(),
			samlProviders: v.pipe(
				v.array(
					samlLoginProviderResponseSchema,
					'EventViewModel.samlProviders'
				),
				v.readonly()
			),
			samlEnabled: v.boolean(),
			eventUrl: v.nullable(v.string()),
			frontPage: v.nullable(v.string()),
			pinLogin: v.boolean(),
			anonLogin: v.boolean(),
			eventOwner: v.nullable(v.string()),
			primaryProjectManager: v.nullable(v.string()),
			disableAnonPassword: v.boolean(),
			skipAnonLoginPage: v.boolean(),
			anonLoginBtnText: v.nullable(v.string()),
			allowAnonUpgrade: v.boolean(),
			forceAnonUpgrade: v.boolean(),
			showLoginSupport: v.boolean(),
			supportBtnText: v.nullable(v.string()),
			showForgotPassword: v.boolean(),
			forgotPasswordText: v.nullable(v.string()),
			landingPageType: v.nullable(v.enum(LandingPageType, 'LandingPageType')),
			downloadableFileTypes: v.pipe(
				v.array(
					v.enum(FileTypes, 'FileTypes'),
					'EventViewModel.downloadableFileTypes'
				),
				v.readonly()
			),
			themeId: v.nullable(v.string()),
			showFooter: v.boolean(),
			footerLine1: v.nullable(v.string()),
			footerLine2: v.nullable(v.string()),
			supportId: v.nullable(v.string()),
			arPopulationJson: v.nullable(v.string()),
			enableModularDashboard: v.boolean(),
		},
		'EventViewModel'
	),
	v.readonly()
);
const eventAdminModelResponseSchema = v.pipe(
	v.object(
		{
			...eventViewModelResponseSchema.entries,
			anonPassword: v.nullable(v.string()),
			deletedAt: dateTimeNullableSchema,
			showTerms: v.boolean(),
			showTermsCheckbox: v.boolean(),
			termsTitle: v.nullable(v.string()),
			termsContent: v.nullable(v.string()),
			showDisclaimer: v.boolean(),
			showDisclaimerCheckbox: v.boolean(),
			disclaimerTitle: v.nullable(v.string()),
			disclaimerContent: v.nullable(v.string()),
		},
		'EventAdminModel'
	),
	v.readonly()
);

const dislaimerViewModelResponseSchema = v.pipe(
	v.object(
		{
			title: v.nullable(v.string()),
			content: v.nullable(v.string()),
			isRead: v.boolean(),
			showCheckbox: v.boolean(),
		},
		'DislaimerViewModel'
	),
	v.readonly()
);
const eventLoadModelResponseSchema = v.pipe(
	v.object(
		{
			event: v.union(
				[eventAdminModelResponseSchema, eventViewModelResponseSchema],
				'EventLoadModel.event'
			),
			client: clientViewModelResponseSchema,
			terms: v.nullable(dislaimerViewModelResponseSchema),
			disclaimer: v.nullable(dislaimerViewModelResponseSchema),
		},
		'EventLoadModel'
	),
	v.readonly()
);

@Injectable({ providedIn: 'root' })
export class ScopeClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #httpClient = inject(HttpClient);

	loadClient({ id, slug }: SlugQuery): Observable<Client> {
		let params = new HttpParams();

		if (id != null) {
			params = params.set('id', id);
		} else if (slug != null) {
			params = params.set('slug', slug);
		}

		return this.#httpClient
			.get<unknown>(`${this.#apiServer}/api/client`, {
				params,
			})
			.pipe(map(response => v.parse(clientViewModelResponseSchema, response)));
	}

	loadAnonClient({ id, slug }: SlugQuery): Observable<Client> {
		let params = new HttpParams();

		if (id != null) {
			params = params.set('id', id);
		} else if (slug != null) {
			params = params.set('slug', slug);
		}

		return this.#httpClient
			.get<unknown>(`${this.#apiServer}/api/client`, {
				params,
			})
			.pipe(map(response => v.parse(clientViewModelResponseSchema, response)));
	}

	updateClient({ id, ...data }: UpdateClientOptions): Observable<Client> {
		return this.#httpClient
			.put<unknown>(`${this.#apiServer}/api/client/${id}`, data)
			.pipe(map(response => v.parse(clientViewModelResponseSchema, response)));
	}

	assignClientPortalEvent(clientId: string, eventId: string): Observable<void> {
		return this.#httpClient.patch<void>(
			`${this.#apiServer}/api/client/${clientId}/assign-portal`,
			{
				eventId,
			}
		);
	}

	unassignClientPortalEvent(
		clientId: string,
		eventId: string
	): Observable<void> {
		return this.#httpClient.patch<void>(
			`${this.#apiServer}/api/client/${clientId}/unassign-portal`,
			{
				eventId,
			}
		);
	}

	loadEvent({ id, slug }: SlugQuery): Observable<EventLoadModel> {
		if (id != null) {
			return this.#httpClient
				.get<unknown>(`${this.#apiServer}/api/event/${id}`)
				.pipe(map(response => v.parse(eventLoadModelResponseSchema, response)));
		}

		let params = new HttpParams();

		if (slug != null) {
			params = params.set('slug', slug);
		}

		return this.#httpClient
			.get<unknown>(`${this.#apiServer}/api/event`, {
				params,
			})
			.pipe(map(response => v.parse(eventLoadModelResponseSchema, response)));
	}

	loadAnonEvent({ id, slug }: SlugQuery): Observable<EventLoadModel> {
		if (id != null) {
			return this.#httpClient
				.get<unknown>(`${this.#apiServer}/api/event/${id}/anon`)
				.pipe(map(response => v.parse(eventLoadModelResponseSchema, response)));
		}

		let params = new HttpParams();

		if (slug != null) {
			params = params.set('slug', slug);
		}

		return this.#httpClient
			.get<unknown>(`${this.#apiServer}/api/event/anon`, {
				params,
			})
			.pipe(map(response => v.parse(eventLoadModelResponseSchema, response)));
	}

	refreshEvent(): Observable<EventInfoDto> {
		return this.#httpClient
			.get<unknown>(`${this.#apiServer}/api/event/refresh`)
			.pipe(map(response => v.parse(eventInfoDtoResponseSchema, response)));
	}

	updateEvent(data: EventUpdateModel): Observable<EventAdminModel> {
		return this.#httpClient
			.put<unknown>(`${this.#apiServer}/api/event/current`, data)
			.pipe(map(response => v.parse(eventAdminModelResponseSchema, response)));
	}

	deleteEvent(eventId: string): Observable<void> {
		return this.#httpClient.patch<void>(
			`${this.#apiServer}/api/event/${eventId}/delete`,
			{}
		);
	}

	undoDeleteEvent(eventId: string): Observable<void> {
		return this.#httpClient.patch<void>(
			`${this.#apiServer}/api/event/${eventId}/undo-delete`,
			{}
		);
	}

	removeForcePassword(dryRun: boolean) {
		return this.#httpClient.patch<AffectedUsersResponse>(
			`${this.#apiServer}/api/user/remove-force-password?dryRun=${dryRun}`,
			{}
		);
	}

	readEventTerms(): Observable<void> {
		return this.#httpClient.patch<void>(
			`${this.#apiServer}/api/event/terms/read`,
			{}
		);
	}

	uploadArPopulation(file: File): Observable<void> {
		const formData = new FormData();
		formData.append('File', file);
		return this.#httpClient.post<void>(
			`${this.#apiServer}/api/event/ar-population`,
			formData
		);
	}

	loadArPopulation(): Observable<string> {
		return this.#httpClient.get(`${this.#apiServer}/api/event/ar-population`, {
			responseType: 'text',
		});
	}

	createEvent(data: EventCreateModel): Observable<EventAdminModel> {
		return this.#httpClient
			.post<unknown>(`${this.#apiServer}/api/event`, data)
			.pipe(map(response => v.parse(eventAdminModelResponseSchema, response)));
	}
}
