import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { FileCacheService } from './file-cache.service';
import { lastValueFrom } from 'rxjs';
import { TokenService } from '@consensus/shared/shared/iam/data-access';
import { environmentToken } from '@environments/environment';
import { capacitorFileDownload } from '@consensus/connect/ufa/capacitor/util-file-download';
import { CoSnackService } from '@consensus/co/ui-snackbars';

@Injectable({ providedIn: 'root' })
export class DownloadService {
	readonly #tokenService = inject(TokenService);
	readonly #fileService = inject(FileCacheService);
	readonly #httpClient = inject(HttpClient);
	readonly #document = inject(DOCUMENT);
	readonly #server = inject(environmentToken).server;
	readonly #snack = inject(CoSnackService);

	downloadFileDirectlyFromUrl(
		url: string,
		fileName?: string,
		clientId?: string
	) {
		if (!url) {
			return;
		}

		const loginToken = this.#tokenService.getLoginToken();
		url = `${this.#server}/api/${url}?access_token=${loginToken}`;
		if (clientId) {
			url += `&clientId=${clientId}`;
		}
		this.downloadFileStream(url, fileName);
	}

	async downloadFileFromUrl(url: string, fileName?: string, showSnack = false) {
		if (!url) {
			return;
		}
		if (showSnack) {
			this.#snack.info('Starting download...');
		}
		const response$ = this.#httpClient.get(url, {
			responseType: 'blob',
			observe: 'response',
		});
		let response: HttpResponse<Blob>;
		try {
			// ISSUE #6488: Undo this workaround [MAB]
			response = await await lastValueFrom(response$);
		} catch {
			response = await await lastValueFrom(response$).catch(err => {
				console.error(err);
				throw new Error('Could not load file');
			});
		}
		if (!fileName) {
			fileName = fileNameFromHeader(response.headers);
		}
		if (!response.body) {
			throw Error('No response body found');
		}
		if (showSnack) {
			this.#snack.info('Finished download');
		}
		capacitorFileDownload({
			blob: response.body,
			filename: fileName ?? 'file',
		});
	}

	async downloadFileFromHttpResponseBlob(
		response: HttpResponse<Blob>,
		appendJsonHeader = false,
		fileName?: string
	) {
		if (!response || !response.body) {
			return;
		}
		if (appendJsonHeader) {
			response.headers.append('Content-Type', 'application/json');
		}
		if (!fileName) {
			fileName = fileNameFromHeader(response.headers);
		}

		capacitorFileDownload({
			blob: response.body,
			filename: fileName ?? 'file',
		});
	}

	async downloadFileFromBlob(blob: Blob, fileName?: string) {
		if (!blob) {
			return;
		}
		capacitorFileDownload({ blob, filename: fileName ?? 'file' });
	}

	async downloadFileFromMediaService(url: string) {
		const file = await this.#fileService.getFile(url);
		if (file.blob) {
			return await this.downloadFileFromBlob(file.blob, file.fileName);
		} else {
			return await this.downloadFileFromUrl(file.url, file.fileName);
		}
	}

	downloadFileStream(url: string, fileName?: string) {
		const a = this.#document.createElement('a');

		a.href = url;
		a.style.display = 'none';

		if (fileName) {
			a.download = fileName;
		}

		this.#document.body.appendChild(a);
		a.click();

		setTimeout(() => {
			a.remove();
		}, 5000);
	}
}

export function fileNameFromHeader(headers: HttpHeaders) {
	const disposition = headers.get('content-disposition');
	if (disposition) {
		const match = disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
		if (match) {
			return match[1].replace(/['"]/g, '');
		}
	}

	return undefined;
}
